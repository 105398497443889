<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="require('@/assets/unknownuser.jpg')"
        >
          <v-card-text class="text-center">
            <validation-observer ref="formPerfil">
              <v-row justify="center">
                <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field v-model="user.rfc"
                      class="purple-input"
                      label="RFC"
                      readonly
                      :disabled="true"
                      color="blue darken-1"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field v-model="user.nombre"
                      class="purple-input"
                       :disabled="true"
                      color="blue darken-1"
                      label="Nombre"
                      readonly
                    />
                  </v-col>



                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field v-model="user.ap_paterno"
                      readonly
                      label="Apellido Paterno"
                       :disabled="true"
                      color="blue darken-1"
                      class="purple-input"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field v-model="user.ap_materno"
                      readonly
                      label="Apellido Materno"
                      color="blue darken-1"
                       :disabled="true"
                      class="purple-input"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >

                   <validation-provider rules="required|email" vid="confirmation" v-slot="{errors}">
                      <v-text-field
                         v-model="user.email"
                        :error-messages="errors[0]"
                        name="input-4-3"
                        label="Correo Electrónico"
                        aria-autocomplete="false"
                        class="purple-input"
                        color="blue darken-1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
              </v-row>
            </validation-observer>
            <v-row justify="center">
              <v-col
               cols="12"
                md="12">

                <v-btn
                  color="deep-orange"
                  elevation="2"
                  @click="openDialog"
                >Cambiar contraseña</v-btn>
                 <v-btn
                  color="primary"
                  elevation="2"
                  @click="guardarCorreo()"
                >Guardar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Resetear Contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <validation-observer ref="formCambiarPassword">
              <v-row>
                  <v-col cols="12" sm="12">
                      <validation-provider rules="required|email" vid="confirmation" v-slot="{errors}">
                        <v-text-field
                          v-model="user.email"
                          :error-messages="errors[0]"
                          name="input-4-3"
                          label="Correo Electrónico"
                          aria-autocomplete="false"
                          class="purple-input"
                          color="blue darken-1"
                        ></v-text-field>
                      </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <validation-provider :rules="{ required: true,min:8,confirmed:'confirmation', is_not:curp }" v-slot="{errors}">
                      <v-text-field
                      :error-messages="errors[0]"
                      v-model="passwordNuevo"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      name="input-10-2"
                      label="Nueva Contraseña"
                      aria-autocomplete="false"
                      hint="Minímo 8 caracteres"
                      class="input-group--focused"
                      @click:append="show2 = !show2"
                      color="blue darken-1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <validation-provider :rules="{ required: true,min:8, is_not:curp }" vid="confirmation" v-slot="{errors}">
                      <v-text-field
                      v-model="passwordConfirm"
                        :error-messages="errors[0]"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        name="input-10-2"
                        label="Confirmar Contraseña"
                        aria-autocomplete="false"
                        class="input-group--focused"
                        @click:append="show3 = !show3"
                        color="blue darken-1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-divider class="p-0"></v-divider>
                  </v-col>

<!-- 
                  <v-col cols="12" md="12">

                    <validation-provider rules="required|min:8" v-slot="{errors}">
                      <v-text-field
                      :error-messages="errors[0]"
                        v-model="passwordActual"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-2"
                        label="Contraseña actual*"
                        aria-autocomplete="false"
                        class="input-group--focused"
                        @click:append="show1 = !show1"
                        color="blue darken-1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
 -->



                <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Legal first name*" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Legal middle name" hint="example of helper text only on focus"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Legal last name*"
                    hint="example of persistent helper text"
                    persistent-hint
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Password*" type="password" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="['0-17', '18-29', '30-54', '54+']"
                    label="Age*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                    label="Interests"
                    multiple
                  ></v-autocomplete>
                </v-col> -->
              </v-row>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="d-flex justify-center"  cols="12" sm="12">
            <v-btn class="mr-3" outlined color="blue darken-2" small @click="dialog = false">Cancelar</v-btn>
            <v-btn color="blue darken-2" small @click="researPassword">Resetear</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
  export default {
    data:function(){

      return {
        overlay:false,
        dialog:false,
        passwordActual:null,
        passwordNuevo:null,
        passwordConfirm:null,
        show1:false,
        show2:false,
        show3:false,
        curp:null,
      }

    },
    computed:{
      ...mapState(["user"])
    },
    mounted(){
      //4m#Nwe13
      //4m$Nwe13
      this.$NProgress.done();

      if(this.user.initial_password == '1'){
        this.$swal({
          title: "Se requiere actualizar la contraseña",
          text: "Si no actualiza su contraseña no podrá entrar a las diferentes secciones del sistema",
          icon: "warning",
          button:"Aceptar"
        });
        this.dialog = true;
      }

      this.curp = this.user.curp
    },
    methods: {
      researPassword(){

        this.$refs.formCambiarPassword.validate().then(async (valid) => {
          if(valid){
            this.dialog = false;
            this.$swal({
              className: "text-center",
              title: "¿Estas seguro de resetear la contraseña?",
              text: "Una vez que la contraseña se haya reseteado se cerrará la sesión",
              icon: "warning",
              allowEscapeKey: false,
              allowOutsideClick: false,
              reverseButtons: true,
              buttons:["Cancelar","Aceptar"],
            }).then(async (value) => {
              if(value){
                try{
                  let form = new FormData();
                  form.append("id_user",this.user.id_user);
                  form.append("email",this.user.email);
                  form.append("old_password",this.passwordActual);
                  form.append("new_password",this.passwordNuevo);
                  this.overlay = true;
                  let response = await this.$http.post("resetear-password",form);
                  if(response.data.response){
                    setTimeout(() => {
                      this.overlay = false;
                      this.logout();
                      // this.$swal({
                      //   title: "Su contraseña es: ",
                      //   text: response.data.password
                      // }).then((value) => {
                      //   this.logout();
                      // });
                      //Bp[[@GR(b:

                    }, 1500);
                  }else {
                    setTimeout(() => {
                      this.overlay = false;
                      this.$swal({
                        title: response.data.message,
                        text: "",
                        icon: "error",
                        button:"Aceptar"
                      }).then(() => this.dialog = true);
                    }, 1500);
                  }

                }catch(error){
                  setTimeout(() => {
                      this.overlay = false;
                      this.$swal({
                        title: "Ocurrio un error :(",
                        text: "",
                        icon: "error",
                        button:"Aceptar"
                      });
                    }, 1500);
                }

              }else{
                this.dialog = true
              }
            });
          }

        });
      },
      guardarCorreo(){
        this.$refs.formPerfil.validate().then(async (valid) => {
          if(valid){
            this.$swal({
              className: "text-center",
              title: "¿Estas seguro de cambiar el correo electrónico?",
              text: "",
              icon: "warning",
              allowEscapeKey: false,
              allowOutsideClick: false,
              reverseButtons: true,
              buttons:["Cancelar","Aceptar"],
            }).then(async (value) => {
              if(value){
                try{
                  let form = new FormData();
                  form.append("id_user",this.user.id_user);
                  form.append("email",this.user.email);
                  this.overlay = true;
                  let response = await this.$http.post("change-email",form);
                  if(response.data.response){
                    setTimeout(() => {
                      this.overlay = false;
                    }, 1500);
                    this.$swal({
                        title: "Se actualizó con éxito",
                        text: "",
                        icon: "success",
                        button:"Aceptar"
                      });

                  }else {
                    setTimeout(() => {

                      this.overlay = false;
                      this.$swal({
                        title: response.data.message,
                        text: "",
                        icon: "error",
                        button:"Aceptar"
                      }).then(() => this.dialog = true);
                    }, 1500);
                  }
                }catch(error){
                  setTimeout(() => {
                      this.overlay = false;
                      this.$swal({
                        title: "Ocurrio un error :(",
                        text: "",
                        icon: "error",
                        button:"Aceptar"
                      });
                    }, 1500);
                }

              }
            });
          }

        });
      },
      async logout(){
        let form = new FormData();
        let token = localStorage.getItem('token');
        form.append("token",token.split(" ")[1]);
        await this.$http.post("logout",form);
        localStorage.removeItem('token');
        this.$store.commit("setAutenticated",false);
        this.$router.push({name:"Login"});
      },
      openDialog(){
        if(this.$refs.formCambiarPassword != undefined){
          this.$refs.formCambiarPassword.reset();
        }
        this.passwordNuevo = null;
        this.passwordActual = null;
        this.passwordConfirm = null;
        this.dialog = true;

      }
    }
  }
</script>
